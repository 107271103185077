import React, {
  Fragment,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Paper,
  Chip,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import styled from '@emotion/styled';

import {
  SaveRounded as SaveIcon,
  CancelRounded as CancelIcon,
  ArrowBackRounded as BackIcon
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

import {
  ExtendedBrand,
  RoleContext,
  useSpot,
  useTabFromQuerystring
} from 'framework';

import {
  Container,
  FileInputButton,
  Gap,
  HorizontalLoadingBar,
  Line,
  Row,
  Spacer,
  Tabs
} from 'components';

import { Error } from 'spot-store';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router';

const TitleField = styled(TextField)`
  input {
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 1.235;
  }
`;

export interface SingleBrandProps {
  displayErrors: (errors: Error[]) => unknown;
  updateBrand: (brand: ExtendedBrand | null) => unknown;
  selectedBrand: ExtendedBrand;
  onBack: () => Promise<unknown>;
}

export function SingleBrand({
  displayErrors,
  updateBrand,
  selectedBrand,
  onBack
}: SingleBrandProps) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { query, raw, loading, spot } = useSpot();

  const [aliasText, setAliasText] = useState<string>('');
  const localFileNameRef = useRef('');

  const { isAdmin } = useContext(RoleContext);

  const { tab, setTab } = useTabFromQuerystring();

  const [copyDefaultSizeCharts, setCopyDefaultSizeCharts] = useState(true);

  const errorText = useCallback(
    fieldName => {
      const requiredFields = ['name', 'baseUrl'];
      const existingFields = ['name'];

      const fieldValue = (selectedBrand as any)[fieldName];

      if (requiredFields.includes(fieldName) && !fieldValue) {
        return t('required');
      }

      if (
        existingFields.includes(fieldName) &&
        spot.data?.brands?.find(
          b =>
            (b as any)[fieldName] === fieldValue && b.id !== selectedBrand?.id
        )
      ) {
        return t('brandExists');
      }
      return undefined;
    },
    [t, selectedBrand, spot]
  );

  const hasError = useCallback(
    fieldName => {
      return !!errorText(fieldName);
    },
    [errorText]
  );

  const validate = useCallback(
    (brandObj: ExtendedBrand) => {
      return !Object.keys(brandObj)
        .map(fieldName => hasError(fieldName))
        .includes(true);
    },
    [hasError]
  );

  const aliasErrorText = useCallback(
    value => {
      if (spot.data?.aliases?.find(a => a.name === value)) {
        return t('brandAliasExists');
      }
      return undefined;
    },
    [t, spot]
  );

  const aliasHasError = useCallback(
    value => {
      return !!aliasErrorText(value);
    },
    [aliasErrorText]
  );

  const saveBrand = useCallback(
    async brandObj => {
      if (brandObj && validate(brandObj)) {
        const brandData = {
          ...brandObj
        };

        brandData.copyDefaultSizeCharts = copyDefaultSizeCharts;

        try {
          const savedBrand = await raw(
            `brand/${brandObj.id > 0 ? brandObj.id : ''}`,
            {
              method: brandObj.id > 0 ? 'PATCH' : 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(brandData)
            }
          );

          if (brandObj.slug) {
            await query(`brand/${brandObj.slug}`, {}, ['extendedBrand']);
          } else {
            navigate(`/brand/${savedBrand?.slug}`);
          }
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [raw, query, navigate, validate, displayErrors, copyDefaultSizeCharts]
  );

  const cancelBrandEdit = useCallback(
    async brandObj => {
      if (brandObj) {
        await query(`brand/${brandObj.slug}`, {}, ['extendedBrand']);
        updateBrand(spot.data.extendedBrand);
      }
    },
    [updateBrand, query, spot]
  );

  const handleLogoUpload = useCallback(
    async (brandObj: ExtendedBrand, file: File) => {
      if (brandObj) {
        const formData = new FormData();
        formData.append('logo', file);

        try {
          const urlResult = await raw<{ url: string; transformedFile: File }>(
            `brand/${brandObj.slug}/logo-upload/${file.name}`,
            {
              method: 'POST',
              body: formData
            }
          );
          const url = urlResult?.url;

          if (url) {
            const logoUrl = new URL(url);
            const logoUrlClean = `${logoUrl.origin}${logoUrl.pathname}`;

            updateBrand({
              ...brandObj,
              logoS3: `${logoUrlClean}`
            });

            localFileNameRef.current = new Date().toISOString();
          }
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [raw, updateBrand, displayErrors]
  );

  const removeAlias = useCallback(
    async (brandObj: ExtendedBrand, alias: string) => {
      if (brandObj) {
        updateBrand({
          ...brandObj,
          aliases: brandObj?.aliases?.filter(a => a !== alias) ?? []
        });
      }
    },
    [updateBrand]
  );

  return (
    <>
      <Row>
        <Typography variant="h4">
          {spot.data?.brands?.length > 1 && (
            <IconButton onClick={onBack} size="large">
              <BackIcon />
            </IconButton>
          )}
          {selectedBrand && selectedBrand?.name !== '' ? (
            <TitleField
              value={selectedBrand.name}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                updateBrand({
                  ...selectedBrand,
                  name: event.target.value
                });
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
            />
          ) : (
            t('newBrand')
          )}
        </Typography>
        <Spacer />
        {isAdmin() && (
          <>
            <Button
              disabled={!validate(selectedBrand)}
              variant="contained"
              color="primary"
              onClick={() => saveBrand(selectedBrand)}
              startIcon={<SaveIcon />}
            >
              {selectedBrand.slug ? t('save') : t('create')}
            </Button>
            <Gap />
            {selectedBrand.slug && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => cancelBrandEdit(selectedBrand)}
                startIcon={<CancelIcon />}
              >
                {t('reset')}
              </Button>
            )}
          </>
        )}
      </Row>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Row>
        <Container>
          {selectedBrand.id <= 0 && (
            <>
              <TextField
                fullWidth
                label={t('name')}
                required
                error={hasError('name') || aliasHasError(selectedBrand.name)}
                helperText={
                  errorText('name') || aliasErrorText(selectedBrand.name)
                }
                onChange={(event: React.ChangeEvent<{ value: string }>) =>
                  updateBrand({
                    ...selectedBrand,
                    name: event.target.value
                  })
                }
                value={selectedBrand.name}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={copyDefaultSizeCharts}
                    onChange={e => setCopyDefaultSizeCharts(e.target.checked)}
                  />
                }
                label={t('copyDefaultSizeCharts')}
              />
            </>
          )}
          {selectedBrand.slug && (
            <Tabs
              selected={tab}
              onTabChange={setTab}
              tabs={{
                basicConfiguration: (
                  <>
                    <Gap />
                    <Row>
                      <Box>
                        <Grid
                          container
                          spacing={1}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <Paper variant="outlined" style={{ fontSize: 0 }}>
                              {selectedBrand.logoS3 || selectedBrand.logo ? (
                                <img
                                  width={200}
                                  height={200}
                                  key={localFileNameRef.current}
                                  src={
                                    selectedBrand.logoS3 ??
                                    selectedBrand.logo ??
                                    ''
                                  }
                                  alt={t('brandLogo')}
                                  style={{ objectFit: 'scale-down' }}
                                />
                              ) : (
                                <Grid
                                  container
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  style={{ width: 200, height: 200 }}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="overline"
                                      align="center"
                                      display="block"
                                    >
                                      {t('noLogo')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                            </Paper>
                          </Grid>
                          {isAdmin() && (
                            <Grid item>
                              <Box display="flex">
                                <Box p={1}>
                                  <FileInputButton
                                    buttonText={t('upload')}
                                    onFileSelected={file =>
                                      handleLogoUpload(selectedBrand, file)
                                    }
                                  />
                                </Box>
                                <Box
                                  p={1}
                                  display={
                                    selectedBrand.logoS3 || selectedBrand.logo
                                      ? 'block'
                                      : 'none'
                                  }
                                >
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      updateBrand({
                                        ...selectedBrand,
                                        logoS3: null,
                                        logo: null
                                      })
                                    }
                                  >
                                    {t('delete')}
                                  </Button>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      <Gap size={4} />
                      <Container>
                        <TextField
                          fullWidth
                          label={t('brandBaseUrl')}
                          required
                          error={hasError('baseUrl')}
                          helperText={errorText('baseUrl')}
                          onChange={(
                            event: React.ChangeEvent<{ value: string }>
                          ) =>
                            updateBrand({
                              ...selectedBrand,
                              baseUrl: event.target.value
                            })
                          }
                          disabled={!isAdmin()}
                          value={selectedBrand.baseUrl}
                        />
                        <Gap />
                        {isAdmin() && (
                          <TextField
                            label={t('addAliasToBrand')}
                            fullWidth
                            margin="normal"
                            error={aliasHasError(aliasText)}
                            helperText={aliasErrorText(aliasText)}
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (
                                event.key === 'Enter' &&
                                aliasText &&
                                aliasText !== '' &&
                                !aliasHasError(aliasText)
                              ) {
                                updateBrand({
                                  ...selectedBrand,
                                  aliases: [
                                    ...(selectedBrand.aliases ?? []),
                                    aliasText
                                  ]
                                });
                                setAliasText('');
                              }
                            }}
                            onChange={(
                              event: React.ChangeEvent<{ value: string }>
                            ) => {
                              setAliasText(event.target.value);
                            }}
                            value={aliasText}
                          />
                        )}
                        <Row>
                          {selectedBrand.aliases?.map(alias => (
                            <Fragment key={alias}>
                              <Chip
                                label={alias}
                                onDelete={
                                  isAdmin()
                                    ? () => removeAlias(selectedBrand, alias)
                                    : undefined
                                }
                              />
                              <Gap />
                            </Fragment>
                          ))}
                        </Row>
                      </Container>
                    </Row>
                  </>
                ),
                retailers:
                  isAdmin() && selectedBrand.retailers?.length ? (
                    <>
                      {selectedBrand.retailers.map(retailer => (
                        <React.Fragment key={retailer.slug}>
                          <Gap />
                          <Row key={`retailer_${retailer.id}`}>
                            <Link to={`/retailer/${retailer.slug}`}>
                              {retailer.name}
                            </Link>
                          </Row>
                        </React.Fragment>
                      ))}
                    </>
                  ) : null
              }}
            />
          )}
        </Container>
      </Row>
    </>
  );
}
